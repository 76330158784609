import React from 'react'
import HeroAbsHotline from "../../../../assets/images/column/abs-hotline.jpeg";
import { Button, Header, Icon, Image, Modal } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css'
const ModalConfirmDonateNav =()=> {

    const [open, setOpen] = React.useState(false)
    return (
            <Modal
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                size="tiny"
                closeOnDimmerClick = {false}
                trigger = {<a title="Donate Food" class="semi-bold">Donate Food Packs</a>}
                >
                <Modal.Header>
                    <div class="right-align">
                    <Icon name="close" link onClick={() => setOpen(false)}></Icon>
                    </div>
                </Modal.Header>
                <Modal.Content >
                    <h4 class="center-align">
                        Thank you for choosing to support ABS-CBN Foundation by pledging in-kind donations for our partner communities.
                    </h4>
                    <p class="italic mrg-bt-10 mrg-top-20">
                        Here are some reminders before proceeding:
                    </p>
                    <p class="mrg-bt-10"> 
                        Once you have submitted your pledge form, the contact person of your chosen partner community will contact you within 3-5 days to coordinate fulfillment of your donation.
                    </p>
                    <p class="mrg-bt-10"> 
                        Pledged in-kind donations should be fulfilled within 7 days after being contacted.
                    </p>
                    <div className="flex center-align mrg-top-20">
                        <a href="https://abs-cbn-foodpack.wecare.ph/" class="button orange flex-auto-vh">Proceed to Community Requests</a>
                    </div>
                </Modal.Content>
            </Modal>
    )
}
export default ModalConfirmDonateNav