import React from 'react'
import {withRouter} from 'react-router-dom'
import ModalAnnouncement from '../ui/ModalAnnouncement.jsx'
import ModalConfirmDonate from '../ui/ModalConfirmDonate.jsx'
import ModalConfirmDonateNav from '../ui/ModalConfirmDonateNav.jsx'
import ModalDonateCash from '../ui/ModalDonateCash.jsx'
import HeroImage from "../../../../assets/images/hero/donation-hero.png";
import WeCareLogo from "../../../../assets/images/wecare-main-logo.svg";
import ShareLoveImage from "../../../../assets/images/column/c4.jpg";
import WereTogetherImage from "../../../../assets/images/column/c2.png";
import Benefit1Icon from "../../../../assets/images/benefits/b1.svg";
import Benefit2Icon from "../../../../assets/images/benefits/b2.svg";
import Benefit3Icon from "../../../../assets/images/benefits/b3.svg";
import Benefit4Icon from "../../../../assets/images/benefits/b4.svg";
import CortexLogo from "../../../../assets/images/cortex-logo.png";
import ConcepcionLogo from "../../../../assets/images/concepcion-logo.jpg";
import AbsCbnLogo from "../../../../assets/images/afi.png";
import HeroImageTyphoon from "../../../../assets/images/column/abs-sagip.png";
import HeroImageTyphoonMobile from "../../../../assets/images/column/abs-sagip.png";
import '../styles/wecare-base.css'
import ReactGa from 'react-ga';
import InitializeReactGa from '../../../../global/services/googleanalytics/index.jsx';
import { repeat } from 'lodash';
const LandingPageNew =()=> {
    InitializeReactGa();
    ReactGa.pageview(window.location.pathname + window.location.search);

    const [open, setOpen] = React.useState(false)

    const backgroundHero = {
      backgroundImage: "url("+HeroImage+")",
    };

    const backgroundHeroTyphoon = {
        backgroundImage: "url("+HeroImageTyphoon+")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"

    };

    const backgroundHeroTyphoonMobile = {
        backgroundImage: "url("+HeroImageTyphoonMobile+")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center"

    };

    const whiteColor = {
      color: "#ffffff",
    };

    const noUnderline = {
      textDecoration: "none",
    };

    const partnerLogo = {
        padding: "0px 5px",
        maxWidth: "100%",
        display: "block",
        margin: "auto"
    };

    const CortexLogoStyle = {
        padding: "0px 5px",
        maxWidth: "80%",
        display: "block",
        margin: "auto"
    };

    return (
        <React.Fragment>
            <nav>
                <div class="nav-wrap">
                    <div class="nav-brand">
                        <a href="/" title="WeCARE.ph">
                            <img src={WeCareLogo} alt="wecare ph"/>
                        </a>
                    </div>
                    <div class="nav-menu">
                        <ul class="nav-menu-list">
                            <li>
                                <ModalConfirmDonateNav></ModalConfirmDonateNav>
                            </li>
                            <li>
                                <a href="/login" title="Donate PPEs" class="semi-bold" style={noUnderline}>Donate PPEs</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <section class="hero">
                <div class="hero-background" style={backgroundHero}></div>

                <div class="hero-background-overlay"></div>
                <div class="hero-content">
                    <div class="hero-text">
                        <h3 class="semi-bold" style={whiteColor}>
                            Together we can make the <br class="desktop-only"/> world a better place
                        </h3>
                        <h5 class="normal" style={whiteColor}>
                            Connect donors and companies in almost <br className="desktop-only"/> every part of the country
                        </h5>
                    </div>
                </div>
            </section>
            <section class="main-content no-pd">
                <div class="flex typ-ul">
                    <div class="flex-one flex-typ-ul">
                        <div class="typ-content">
                            <div class="flex-auto-vertical">
                                <img src={HeroImageTyphoon} alt="ABS CBN Sagip Kapamilya" className="img-full-width flex-auto-vh mobile-only mrg-bt-10"/>
                                <h3 className="semi-bold">Tulong-Tulong sa Pag-Ahon</h3>
                                <h5 class="normal mrg-top-5">
                                    A fundraising campaign to support the victims of <br className="desktop-only"/> Typhoon Ulysses
                                </h5>
                                <ModalAnnouncement></ModalAnnouncement>
                            </div>
                        </div>
                    </div>
                    <div class="flex-one">
                        {/* <div class="bg-holder desktop-only" style={backgroundHeroTyphoon}></div> */}
                        <br class="mobile-only"/>
                        <br class="mobile-only"/>
                        <img src={HeroImageTyphoon} alt="ABS CBN Sagip Kapamilya" className="img-full-width flex-auto-vh desktop-only"/>
                        {/* <div class="bg-holder mobile-only" style={backgroundHeroTyphoonMobile}></div> */}
                    </div>
                </div>
            </section>
            <section class="main-content">
                <div class="container-main">
                    <div class="row group flex row-feature">
                        <div class="col span6 flex flex-direction-column">
                            <div class="center-align flex flex-direction-column flex-full-height">
                                <h3 class="semi-bold">Help Feed Filipino Families</h3>
                                <div class="img-holder img-main">
                                    <img src={ShareLoveImage} class="img-full-width" alt="Help Feed Filipino Families"/>
                                </div>
                                <div class="mrg-top-10">
                                    <p>
                                       A lot of Filipinos are still unable to recover as our economy continues to plummet amidst the COVID-19 pandemic. Families are still struggling to put food on their tables with the loss of jobs across different industries. <span class="bold">ABS-CBN Foundation's Pantawid ng Pag-ibig</span> aims to help Filipino families by distributing food packs to communities in need.

                                    </p>

                                </div>
                                <div class="mrg-top-20 flex action-btn flex-one">
                                <ModalConfirmDonate></ModalConfirmDonate>
                                <br className="mobile-only"/>
                                <br className="mobile-only"/>
                                <a href="http://pantawidngpagibig.com/" className="button basic-orange flex-fixed-bottom">Donate Cash</a>
                                </div>
                            </div>
                        </div>
                        <div class="col span6 flex flex-direction-column">
                            <div class="center-align flex flex-direction-column flex-full-height">
                                <h3 class="semi-bold">Help Fight COVID-19</h3>
                                <div class="img-holder img-main">
                                    <img src={WereTogetherImage} alt="We're in this Together" class="img-full-width"/>
                                </div>
                                <div class="mrg-top-10">
                                    <p>
                                        Supplies are running low and more of our frontliners are battling the virus, join our campaign to provide or request PPEs to help fight the Pandemic.
                                    </p>
                                </div>
                                <div class="mrg-top-20 flex action-btn flex-one">
                                    <a href="/login" class="button orange flex-fixed-bottom mrg-right-10" style={noUnderline}>Donate PPEs</a>
                                    <ModalDonateCash></ModalDonateCash>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="clear"></div>
            <section class="main-content gray">
                <div class="container-main">
                    <div class="center-align content-title">
                        <h3 class="center-align semi-bold">
                            What is WeCARE?
                        </h3>
                        <h5 class="normal">We Connect, Act and Respond</h5>
                    </div>
                    <div class="mrg-top-40 desktop-only"></div>
                    <div class="row group row-benefits">
                        <div class="col span3">
                            <div class="wecare-benefits">
                                <img src={Benefit1Icon} alt="icon We’re not for Profit"/>
                                <h4 class="normal semi-bold">We’re not for Profit</h4>
                                <p>
                                    WeCARE's mission is to help donors have more impact for a greater cause.
                                </p>
                            </div>  
                        </div>
                        <div class="col span3">
                            <div class="wecare-benefits">
                                <img src={Benefit2Icon} alt="icon Community Based"/>
                                <h4 class="normal semi-bold">Community Based</h4>
                                <p>
                                    Through WeCARE, amazing people provide and help each other with what they need.
                                </p>
                            </div>  
                        </div>
                        <div class="col span3">
                            <div class="wecare-benefits">
                                <img src={Benefit3Icon} alt="icon Easy to Use"/>
                                <h4 class="normal semi-bold">Easy to Use</h4>
                                <p>
                                    Anyone can use WeCARE to donate, and we're here to help in every step.
                                </p>
                            </div>  
                        </div>
                        <div class="col span3">
                            <div class="wecare-benefits">
                                <img src={Benefit4Icon} alt="icon Trusted Information"/>
                                <h4 class="normal semi-bold">Trusted Information</h4>
                                <p>
                                    You can trust the people using WeCARE as we verify our users for everyone's safety.
                                </p>
                            </div>  
                        </div>
                    </div>
                </div>
            </section>
            <section class="main-content">
                <div class="container-main">
                    <div class="center-align content-title">
                        <h3 class="center-align semi-bold h3-m">
                        A joint CSR Program by Concepcion Industrial Corporation and Cortex Technologies, Inc.,  in partnership with ABS-CBN Foundation
                        </h3>
                        <h5 class="normal">Doing more together to reach the people in need.</h5>
                    </div>
                    <div class="mrg-top-40 desktop-only"></div>
                    <div class="row group">
                        <div class="col span1"><span class="desktop-only">&nbsp;</span></div>
                        <div class="col span10">
                            {/* <div class="flex mrg-top-10">
                                <div class="flex flex-one">
                                    <a class="img-holder img-partner flex-auto-vh flex" href="https://www.cortex.ph/" style={partnerLogo}>
                                        <img src={CortexLogo} alt="cortext technologies" class="img-full-width"/>
                                    </a>
                                </div>
                                <div class="flex-one flex">
                                    <a class="img-holder img-partner flex flex-auto-vh" href="https://www.cic.ph/" style={partnerLogo}>
                                        <img src={ConcepcionLogo} alt="concepcion industrial corporation" class="img-full-width"/>
                                    </a>
                                </div>
                                <div class="flex-one flex">
                                    <a class="img-holder img-partner flex flex-auto-vh" href="https://corporate.abs-cbn.com/lingkodkapamilya/" style={partnerLogo}>
                                        <img src={AbsCbnLogo} alt="abs-cbn foundation" class="img-full-width"/>
                                    </a>
                                </div>
                            </div> */}
                            <div class="row group partner-row">
                                <div class="col span4 m-50">
                                    <a class="img-holder img-partner" href="https://www.cic.ph/" style={partnerLogo}>
                                        <img src={ConcepcionLogo} alt="concepcion industrial corporation" class="img-full-width"/>
                                    </a>
                                    <div class="mrg-bt-15 mobile-only"></div>
                                </div>

                                <div class="col span4 m-50 m-501">
                                    {/* <div class="mrg-top-10 desktop-only"></div> */}
                                    <a class="img-holder img-partner" href="https://www.cortex.ph/" style={CortexLogoStyle}>
                                        <img src={CortexLogo} alt="cortext technologies" class="img-full-width"/>
                                    </a>
                                    <div class="mrg-bt-15 mobile-only"></div>
                                </div>
                                <div class="col span4 m-70">
                                    <a class="img-holder img-partner" href="https://corporate.abs-cbn.com/lingkodkapamilya/" style={partnerLogo}>
                                        <img src={AbsCbnLogo} alt="abs-cbn foundation" class="img-full-width"/>
                                    </a>
                                    <div class="mrg-bt-15 mobile-only"></div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
            <footer>
                <div class="container-main">
                    <div class="row group">
                        <div class="col span12">
                            <div class="center-align">
                            <p class="mrg-bt-10 semi-bold">DISCLAIMER</p>
                            This is not a public solicitation. This application serves to gather information on
                            available items for donation and the number of individuals who seeks said donation. 
                            <a class="semi-bold white-color" href="https://corporate.abs-cbn.com/lingkodkapamilya/" style={noUnderline}> ABS-CBN Lingkod Kapamilya Foundation, Inc. (ALKFI)</a> and <a href="https://www.cic.ph/" class="white-color semi-bold " style={noUnderline}>Concepcion Industrial Corporation (CIC)</a> assume no liability or responsibility for any errors or omissions made by any
                            party who discloses information using the application. The information provided by each
                             party has no guarantees of completeness, reliability and accuracy without any
                             warranties whatsoever. Any action you take from the information as herein provided is
                             strictly at your own risk.
                            </div>
                        </div>
                        
                    </div>
                </div>
            </footer>
            
        </React.Fragment>
    )
}
export default withRouter(LandingPageNew)